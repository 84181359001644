import React from "react";
import Constant from "../constants";

const NotFound = React.memo((props) => {
  const { msg=Constant.BROKEN_MSG, title=Constant.PAGE_NOT_FOUND } = props;

  return (
    <>
      <section className="team mTop-80">
        <div className="container" data-aos="fade-up">
          <div className="mLeft-30 form-wrapper">
      <div className="btn-center mTop-80 mBot-30">
        <h2>{title}</h2>
        <br />
        {msg}
       {" "}
        {/* <a href="https://enquiry.caas.gov.sg/caas/apps/Feedback.aspx?CATEGORY_ID=cdbb76c0-c3a5-45e6-b9f2-a6782fe5dfee"> */}
        <a href="https://go.gov.sg/caas-enquiries-feedback-form">
        </a>
        .
        <br />
        </div>
        </div>
        </div>
        </section>
    </>
  );
});

export default NotFound;
