import { useEffect } from "react";

export default function PublicRoute({ titleMeta, descriptionMeta, children }) {
  useEffect(() => {
    const metaList = document.getElementsByTagName("meta");
    for (let i = 0; i < metaList.length; i++) {
      if (metaList.item(i).name === "title") {
        metaList.item(i).content = titleMeta;
      }

      if (metaList.item(i).name === "description") {
        metaList.item(i).content = descriptionMeta;
      }
    }
  }, [titleMeta, descriptionMeta]);

  return children;
}
