import React, { useState, createContext, useContext } from "react";

const AuthContext = createContext(null);

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState({status: "just some non-empty string otherwise re-render will not be triggered when user changes",});

  return (
    <AuthContext.Provider value={{ user: currentUser, setUser: (value) => setCurrentUser(value) }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
