import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export const ConcurrentLoginModal = ({ show, onHide }) => {

    /*
    useEffect(() => {
        console.log("useEffect -->"+showModal);
        console.log("useEffect concurrentAlertShown-->"+isConcurrentLoginAlertShown);
        if(!isConcurrentLoginAlertShown){
        setShow(showModal);
        }
    }, [isConcurrentLoginAlertShown]);
    */

    return (
        <Modal show={show} onHide={onHide} backdrop="static" size="lg">
            <Modal.Header closeButton>
                <Modal.Title><i className="bi bi-check-lg check-icon" />Concurrent Logins Detected</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                You have an active login session from another device or browser, which has been logged out now.</Modal.Body>
            <Modal.Footer className="text-center confirm-center-footer">
                <Button className="btn btn-primary sgds btn-gap btn-caas btn-primary-caas" onClick={onHide}>
                    Close
                </Button>                
            </Modal.Footer>
        </Modal>
    )
}