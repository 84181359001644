import React, { useState, useEffect, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSnackbar } from "react-snackbar-messages";

import { useAuth } from "../Providers/AuthProvider";
import Header from "../Components/Common/Header/Header";
import CaasFooter from "../Components/Common/Footer/Footer";
import Loader from "../Components/Loader";
import NotFound from "./NotFound";
import { ConcurrentLoginModal } from "../Components/Common/ConcurrentLoginModal";
import IdleTimeOutHandler from "../Components/Common/SessionTimeoutModal/IdleTimeoutHandler";

const Root = () => {
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const { user, setUser } = useAuth();

  const [isLoadingLogout, setIsLoadingLogout] = useState(false);

  const [isLoadingCurrentUser, setIsLoadingCurrentUser] = useState(true);
  const [isErrorCurrentUser, setIsErrorCurrentUser] = useState(false);

  const [isShowConcurrentLoginModal, setIsShowConcurrentLoginModal] =
    useState(false);

  const [isActive, setIsActive] = useState(true);

  const handleLogout = () => {
    setIsLoadingLogout(true);
    axios
      .get("/login-service/logout")
      .then((_response) => {
        setUser({ status: "" });
        navigate("/", { replace: true });
      })
      .catch((error) => {
        snackbar.add("Error: unable to logout!", {
          appearance: "error",
          autoDismiss: true,
        });
        console.log("Error logout: ", error);
      })
      .finally(() => {
        setIsLoadingLogout(false);
      });
  };

  const fetchUser = () => {
    setIsLoadingCurrentUser(true);
    axios
      .get("/login-service/status")
      .then((response) => {
        const responseData = response.data;
        if (
          responseData.userSessionValid &&
          responseData.foundMultipleSessions
        ) {
          setIsShowConcurrentLoginModal(true);
        }
        setIsErrorCurrentUser(false);
        setUser(responseData);
      })
      .catch((error) => {
        snackbar.add("Error: unable to retrieve user", {
          appearance: "error",
          autoDismiss: true,
        });
        setIsErrorCurrentUser(true);
        console.log("Error fetching current user: ", error);
      })
      .finally(() => {
        setIsLoadingCurrentUser(false);
      });
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <>
      <Header handleLogout={handleLogout} />
      {isLoadingLogout || isLoadingCurrentUser ? (
        <Loader show={true} />
      ) : isErrorCurrentUser ? (
        <NotFound />
      ) : (
        <Suspense fallback={<Loader show={true} />}>
          <Outlet />
        </Suspense>
      )}
      <CaasFooter />

      <ConcurrentLoginModal
        show={isShowConcurrentLoginModal}
        onHide={() => setIsShowConcurrentLoginModal(false)}
      />

      {user?.userSessionValid && user.status === "ACTIVE" ? (
        <IdleTimeOutHandler
          onActive={() => {
            setIsActive(true);
          }}
          onIdle={() => {
            setIsActive(true);
          }}
          onLogout={handleLogout}
          continueSession={fetchUser}
        />
      ) : null}
    </>
  );
};

export default Root;
