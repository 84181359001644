import React from "react";
import { Spinner, Modal } from "react-bootstrap";

const Loader = React.memo((props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="overlay-spinner"
    >
      <Modal.Body>
        <Spinner animation="border" variant="light" />
      </Modal.Body>
    </Modal>
  );
});

export default Loader;
