import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export const IdleTimeOutModal = ({ showModal, handleContinue, handleLogout, remainingTime }) => {

    return (
        <Modal show={showModal} onHide={handleContinue}>
            <Modal.Header closeButton>
                <Modal.Title>Session Timeout!</Modal.Title>
            </Modal.Header>
            <Modal.Body>Your session is Timed Out. You want to stay?</Modal.Body>
            <Modal.Footer className="text-center confirm-center-footer">
                <Button className="btn btn-primary sgds btn-gap btn-caas btn-primary-caas" onClick={handleLogout}>
                    Logout
                </Button>
                <Button className="btn btn-primary sgds btn-gap btn-caas btn-primary-caas" onClick={handleContinue}>
                    Continue Session
                </Button>
            </Modal.Footer>
        </Modal>
    )
}