import { useEffect } from 'react'
import { useAuth } from '../Providers/AuthProvider';
import { useNavigate } from 'react-router-dom';

export default function ProtectedRoute({ children }) {
    const { user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!user?.userSessionValid || !user?.status === "ACTIVE") {
            navigate("/login", { replace: true });
        }
    }, [navigate, user]);

  return children;
}
