import ReactRecaptcha3 from "react-google-recaptcha3";
import React, { useEffect, lazy } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { SnackbarProvider } from "react-snackbar-messages";

import Constant from "./constants";
import PublicRoute from "./routes/PublicRoute";
import ProtectedRoute from "./routes/ProtectedRoute";
import AuthProvider from "./Providers/AuthProvider";
import "@govtechsg/sgds/css/sgds.css";
import "./css/theme.css";
import "./css/styles.css";
import "./Components/Common/CountryPhone/css/bootstrap.scss";
import Root from "./Pages/Root";
import NotFound from "./Pages/NotFound";
import IndexPage from "./Pages/IndexPage";

const Login = lazy(() => import("./Pages/Login/Login"));
const FormEditDetail = lazy(() => import("./Components/FormEditDetail"));
const Dashboard = lazy(() => import("./Pages/Dashboard"));
const ApplyPermit = lazy(() => import("./Pages/ApplyPermit"));
const ApplicationDetails = lazy(() => import("./Components/ApplicationDetails"));
const CheckPermitEligibility = lazy(() => import("./Components/PermitEligibilityChecker/CheckPermitEligibility"));
const UserProfile = lazy(() => import("./Pages/Profile/UserProfile"));
const UserStatus = lazy(() => import("./Pages/Profile/UserStatus"));
const NewUser = lazy(() => import("./Pages/Profile/NewUser"));
const Slider = lazy(() => import("./Components/Common/Carousel/Slider"));

const App = () => {

  useEffect(() => {
    ReactRecaptcha3.init(Constant.SITE_KEY);
  }, []);

  return (
    <SnackbarProvider>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Root />} errorElement={<NotFound />}>
              <Route index={true} element={<IndexPage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/newUser" element={<NewUser />} />
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute>
                    <UserProfile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/home"
                element={
                  <PublicRoute
                    titleMeta="Civil Aviation Authority of Singapore (CAAS)"
                    descriptionMeta="CAAS AHOMS Internet Application"
                  >
                    <Slider />
                  </PublicRoute>
                }
              />
              <Route
                path="/apply/permit"
                element={
                  <ProtectedRoute>
                    <ApplyPermit />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/application/details"
                element={
                  <ProtectedRoute>
                    <ApplicationDetails />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/application/details/edit"
                element={
                  <ProtectedRoute>
                    <FormEditDetail />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/checkPermitEligibility"
                element={
                  <PublicRoute
                    titleMeta="(CAAS) | Permit Eligibility Check"
                    descriptionMeta="Check if you need permit/clearance for your planned activity."
                  >
                    <CheckPermitEligibility />
                  </PublicRoute>
                }
              />
              <Route path="/ErrorPage" element={<NotFound />} />
              <Route path="/userStatus" element={<UserStatus />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </Router>
      </AuthProvider>
    </SnackbarProvider>
  );
};

export default App;
