import React from "react";
import { Footer } from "@govtechsg/sgds-react/Footer";
import { Container } from "react-bootstrap";
import Constant from "../../../constants";
import moment from "moment";

const CaasFooter = React.forwardRef((props, ref) => {
  const year = moment(new Date()).format('YYYY');
  return (
    <Footer>
      <Footer.Top>
        <Container>
          <Footer.Top.Header
            headerTitle={Constant.SKYSAFE}
          ></Footer.Top.Header>
          <Footer.Top.ItemGroup>
            <Footer.Top.Item itemTitle={Constant.APPLY_FOR_OTHER_SERVICE}>
              <a href={Constant.GO_BUSINESS}>GoBusiness</a>
            </Footer.Top.Item>
          </Footer.Top.ItemGroup>
          <Footer.Top.ContactLinks>
            <a href={Constant.CONTACT_US}>Contact Us</a>
            <a href={Constant.FEED_BACK}>Feedback</a>
            <a href={Constant.CONTACT_US}>FAQ's</a>
            <a href="https://www.reach.gov.sg/">REACH</a>
            <a href="https://www.reach.gov.sg/">Useful Links</a>
          </Footer.Top.ContactLinks>
        </Container>
      </Footer.Top>
      <Footer.Bottom>
        <Container>
          <hr />
          <Footer.Bottom.Links>
            <a
              href={Constant.REPORT_VULNER}
              target="_blank"
              rel="noopener noreferrer"
            >
              Report Vulnerability
            </a>
            <a href={Constant.PRIVACY_STATEMENT}>Privacy</a>
            <a href={Constant.TERMS_OF_USE}>Terms of use</a>
          </Footer.Bottom.Links>
          <Footer.Bottom.Copyrights>
            © {year} Government of Singapore
            <br></br>
            {Constant.FOOTER_LAST_UPDATED_DATE_TEXT}
          </Footer.Bottom.Copyrights>
        </Container>
      </Footer.Bottom>
    </Footer>
  );
});

export default CaasFooter;
