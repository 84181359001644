import { useEffect } from "react";
import { useAuth } from "../Providers/AuthProvider";
import { useNavigate } from "react-router-dom";

const IndexPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.status) {
      if (user.status === "PENDING_REGISTRATION") {
        navigate("/profile");
      } else if (user.status === "SUBMITTED") {
        navigate("/userStatus");
      } else if (user.status === "ACTIVE") {
        navigate("/dashboard");
      } else if (
        user.status === "DISABLED" ||
        user.status === "DELETED" ||
        user.status === "REJECTED"
      ) {
        navigate("/userStatus");
      }
    } else {
      navigate("/home");
    }
  }, [navigate, user]);

  return null;
};

export default IndexPage;
