import React, { useState } from 'react';
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import { Container, Navbar, Nav, Dropdown } from "react-bootstrap";

import { useAuth } from '../../../Providers/AuthProvider';
import "./header.css";

const Header = ({ handleLogout }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useAuth();

  const [isExpanded, setIsExpanded] = useState(false);

  const userProfileClick = () => {
    const status = user?.status;
    if (status === "PENDING_REGISTRATION") {
      navigate("/profile");
    } else if (status === "SUBMITTED") {
      navigate("/userStatus");
    } else if (status === "ACTIVE") {
      navigate("/profile");
    } else if (
      status === "DISABLED" ||
      status === "DELETED" ||
      status === "REJECTED"
    ) {
      navigate("/userStatus");
    }
  };

  const headerElement = document.getElementById("header");
  window.onscroll = () => {
    if (window.scrollY > 100) {
      headerElement?.classList.add("header-scrolled");
    } else {
      headerElement?.classList.remove("header-scrolled");
    }
  };

  return (
    <>
      <header id="header" className="header fixed-top">
        <div className="sgds-masthead-band">
          <Container>
            <sgds-masthead></sgds-masthead>
          </Container>
        </div>

        <Navbar expand="lg" expanded={isExpanded}>
          <Container>
            <Navbar.Brand href="/">
              <img
                src={process.env.PUBLIC_URL + "/images/logo.jpg"}
                alt="CAAS"
                width="127px"
                height="71px"
              />
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              onClick={() => setIsExpanded((prev) => !prev)}
            />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                {user?.status === "ACTIVE" && (
                  <Nav.Item>
                    <Nav.Link
                      to={"/dashboard"}
                      as={NavLink}
                      onClick={() => setIsExpanded(false)}
                    >
                      Dashboard
                    </Nav.Link>
                  </Nav.Item>
                )}
                <Nav.Item>
                  <Nav.Link
                    to={"/home"}
                    as={NavLink}
                    onClick={() => setIsExpanded(false)}
                  >
                    Apply
                  </Nav.Link>
                </Nav.Item>
                {(user?.status === "SUBMITTED" ||
                  user?.status === "DISABLED" ||
                  user?.status === "DELETED" ||
                  user?.status === "REJECTED") && (
                  <Nav.Item>
                    <Nav.Link
                      to={"/userStatus"}
                      as={NavLink}
                      onClick={() => setIsExpanded(false)}
                    >
                      User Status
                    </Nav.Link>
                  </Nav.Item>
                )}
                {!user?.spcpLogin &&
                  user?.email &&
                  user?.status === "PENDING_REGISTRATION" && (
                    <Nav.Item>
                      <Nav.Link
                        to={"/profile"}
                        as={NavLink}
                        onClick={() => setIsExpanded(false)}
                      >
                        User Registration
                      </Nav.Link>
                    </Nav.Item>
                  )}
                <Nav.Item>
                  <Nav.Link
                    to={"/checkPermitEligibility"}
                    as={NavLink}
                    onClick={() => setIsExpanded(false)}
                  >
                    Enquiry Tool
                  </Nav.Link>
                </Nav.Item>
                {(user?.spcpLogin ||
                  (user?.email &&
                    (user?.status === "ACTIVE" ||
                      user?.status === "SUBMITTED" ||
                      user?.status === "PENDING_REGISTRATION"))) && (
                  <>
                    <Nav.Item>
                      <Nav.Link
                        className="hide-lg"
                        to={"/profile"}
                        as={NavLink}
                        onClick={() => setIsExpanded(false)}
                      >
                        User Profile
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        className="hide-lg"
                        onClick={() => {
                          handleLogout();
                          setIsExpanded(false);
                        }}
                        as="a"
                      >
                        Logout
                      </Nav.Link>
                    </Nav.Item>
                  </>
                )}
              </Nav>

              {((!user?.spcpLogin && !user?.email) ||
                !user?.userSessionValid) &&
              !(location.pathname === "/login") ? (
                <a
                  className="btn-caas btn-primary-caas scrollto nav-login"
                  href="#/login"
                  onClick={() => setIsExpanded(false)}
                >
                  <span>Login / Signup</span>
                </a>
              ) : user?.spcpLogin ||
                (user?.email &&
                  (user?.status === "ACTIVE" ||
                    user?.status === "SUBMITTED" ||
                    user?.status === "PENDING_REGISTRATION")) ? (
                <>
                  <Dropdown className="hide-sm">
                    <Dropdown.Toggle variant="" id="dropdown-basic">
                      <span>
                        <span>
                          <i className="bi bi-person-fill"></i>
                        </span>
                        &nbsp;
                        {(user && user?.fullName) || ""}
                      </span>
                      &nbsp;
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={userProfileClick}>
                        <span>User Profile &nbsp;</span>
                      </Dropdown.Item>

                      {user && (user?.email || user?.spcpLogin) && (
                        <Dropdown.Item onClick={handleLogout}>
                          <span>
                            Logout &nbsp;
                            <span>
                              <i className="bi bi-arrow-right"></i>
                            </span>
                          </span>
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              ) : null}
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
};

export default Header;
